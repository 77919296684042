export default (active, count, speed) => ({
  active,
  count,
  speed,
  cycle: null,

  init() {
    this.startCycle();
  },

  startCycle() {
    this.cycle = setInterval(() => {
      this.active = this.active < count - 1 ? this.active + 1 : 0;
    }, this.speed * 1000);
  },

  activate(i) {
    clearInterval(this.cycle);

    this.active = i;

    this.startCycle();
  },

  isActive(i) {
    return this.active === i;
  },
});
