import { intersectEffect } from "./intersect-effect";

export default (customPosts, relatedPosts, currentPost, queryArgs) => ({
  customPosts,
  relatedPosts,
  currentPost,
  queryArgs,
  page: 1,
  totalPages: null,
  query: "",
  cards: [],
  loading: true,

  get cardsHtml() {
    return this.cards.map((card) => card.html).join("");
  },

  init() {
    this.refresh();
  },

  refresh() {
    this.loading = true;

    fetch(
      "/wp-json/meladra/v1/press?" +
        new URLSearchParams({
          customPosts: this.customPosts,
          relatedPosts: this.relatedPosts,
          currentPost: this.currentPost,
          page: this.page,
          query: this.query,
          ...this.queryArgs,
        }).toString(),
    )
      .then((response) => response.json())
      .then((data) => {
        this.page = data.page;
        this.totalPages = data.totalPages;
        this.cards = data.cards;

        this.loading = false;

        this.$nextTick(() => {
          intersectEffect(".fade", "fade-init", 0.25, 0);
        });
      });
  },

  search() {
    this.refresh();
  },

  get canPrev() {
    return this.page !== 1;
  },

  prev() {
    this.page--;
    this.refresh();
  },

  get canNext() {
    return this.page !== this.totalPages && this.totalPages !== 0;
  },

  next() {
    this.page++;
    this.refresh();
  },

  viewPage(index) {
    this.page = index;
    this.refresh();
  },
});
