import "../css/meladra.css";

import { intersectEffect } from "./components/intersect-effect";

import Alpine from "alpinejs";

import pressBlock from "./components/press-block";
import heroSlider from "./components/hero-slider";
import modal from "./components/modal";

window.Alpine = Alpine;
window.MeladraIntersectEffect = intersectEffect;

Alpine.data("pressBlock", pressBlock);
Alpine.data("heroSlider", heroSlider);
Alpine.data("modal", modal);

Alpine.store("modal", modal);

Alpine.store("mobileMenu", { open: false });

Alpine.start();

(function () {
  document
    .querySelectorAll(
      "\
      h2:not(.no-fade),\
      h3:not(.no-fade),\
      h4:not(.no-fade),\
      .wp-block-button:not(.no-fade),\
      .wp-block-image:not(.no-fade, .is-style-full),\
      .wp-block-post-featured-image:not(.no-fade, .is-style-full)",
    )
    .forEach(($el) => $el.classList.add("fade"));

  intersectEffect(".fade", "fade-init", 0.25);

  // AlpineJS Modal
  document.querySelectorAll(`[href^="#modal-"]`).forEach(($el) => {
    $el.addEventListener("click", (ev) => {
      ev.preventDefault();

      Alpine.store("modal").open(
        $el.getAttribute("href").replace("#modal-", ""),
      );
    });
  });
})();
